document.addEventListener('DOMContentLoaded', function () {
  var mv_swiper = new Swiper('.js-slider', {
    loop: false,
    slidesPerView: 'auto',
    // speed: 1000,
    spaceBetween: 10,
    // autoplay: {
    //   delay: 5000,
    // },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    scrollbar: {
      el: '.swiper-scrollbar',
      draggable: true,
    },
    breakpoints: {
      // when window width is >= 320px
      769: {
        spaceBetween: 50,
      },
      // when window width is >= 480px
    }
  });
})